if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'slick-carousel';
import 'waypoints/lib/noframework.waypoints.min.js';
var Masonry = require('masonry-layout');

var breakpointMobile = 768;

window.onload = function() {
  setTimeout(function(){$('main.holder').removeClass('prevent-transition');}, 100);
}

$(document).ready(function(){

  $('.product-carousel').slick({
    slidesToShow: 2,
    slidesToScroll: 2
  });

  $('.floor-links a, .three-floors .watch-now').hover(function(){
    var hoverIndex = $('.floor-links a').index(this);
    if (hoverIndex == -1){
      var hoverIndex = $('.three-floors .watch-now').index(this);
    }
    $('.floor-links a')[hoverIndex].classList.toggle('hover');
    $('.three-floors .watch-now')[hoverIndex].classList.toggle('hover');
  })
})
